import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { BandasService } from '../_services/bandas.service';
import { NoticiasService } from '../_services/noticias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';

@Component({
  selector: 'detallebanda',
  templateUrl: 'detallebanda.template.html',
  styleUrls: ['detallebanda.template.css'],
})
export class DetalleBandaComponent implements OnInit, OnDestroy {

  banda: any = {};
  id_banda: any;
  
  cofradia: any = {};

  verdetalles: boolean = false;
  
  cofradias: any = [];
  cofradiasfuerajaen: any = [];

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private bandasService: BandasService,
    private activatedRoute: ActivatedRoute) {

    this.id_banda = activatedRoute.snapshot.params["id_banda"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.banda= this.router.getCurrentNavigation().extras.state.banda;
        this.cofradia = this.router.getCurrentNavigation().extras.state.cofradia;
      }
    });
    
  }

  ngOnInit() {

  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    this.getCofradias();
    this.getCofradiasFueraJaen();
  }

  back(){
    if (!this.cofradia){
      this.router.navigate(['/bandas/']);
    }else{
      this.router.navigate(['/cofradia/',this.cofradia.id_cofradia]);
    }
  }
  
  getTipo(banda: any){
    if (banda.tipo == 1){
      return "Agrupación musical";
    }
    if (banda.tipo == 2){
      return "Cornetas y Tambores";
    }
    if (banda.tipo == 3){
      return "Banda de música";
    }
    if (banda.tipo == 4){
      return "Música de capilla";
    }
  }
  
  getDark(img){
    if (img){
      return img += '_dark';
    }else{
      return img;
    }
  }
  
  errorReporting(){
    
    console.log("voy a error");
    
    let navigationExtras: NavigationExtras = { state: {from: this.router.url} };
    this.router.navigate(['error'], navigationExtras);
    
  }
  
  getCofradias(){
    this.bandasService.getCofradias(this.banda.id).subscribe(
      data => {
        var response: any = data;
        this.cofradias = response.cofradias;
      }
    )
  }
  
  getCofradiasFueraJaen(){
    this.bandasService.getCofradiasFueraJaen(this.banda.id).subscribe(
      data => {
        var response: any = data;
        this.cofradiasfuerajaen = response.cofradias;
      }
    )
  }
  
  getDarkCofradia(img){
    if (img){
      return img.replace('.png', '_dark.png');
    }else{
      return img;
    }
  }
  
  getCofradia(cofradia:any){
    
    cofradia.id_cofradia = cofradia.id;
    
    let navigationExtras: NavigationExtras = { state: {cofradia: cofradia} };
    this.router.navigate(['cofradia',cofradia.id], navigationExtras);
    
  }
  
  getDia(dia: any){
    if (dia == 0){
      return "Sábado de Pasión";
    }
    if (dia == 1){
      return "Domingo de Ramos";
    }
    if (dia == 2){
      return "Lunes Santo";
    }
    if (dia == 3){
      return "Martes Santo";
    }
    if (dia == 4){
      return "Miércoles Santo";
    }
    if (dia == 5){
      return "Jueves Santo";
    }
    if (dia == 6){
      return "Madrugada";
    }
    if (dia == 7){
      return "Viernes Santo";
    }
    if (dia == 8){
      return "Domingo de Resurrección";
    }
    if (dia == 9){
      return "Sábado Santo";
    }
  }


}
