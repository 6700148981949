import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()

export class NoticiasService {

    constructor(private http: HttpClient) { }

    getNoticias(){

        var url = 'http://pasionenjaen.com/rss_app_ios_json.php';
        return this.http.get(url);

    }

    getUltimaNoticia(){

        var url = 'http://pasionenjaen.com/rss_app_ios_json.php?limit=1';
        return this.http.get(url);

    }

}