import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { CofradiasService } from '../_services/cofradias.service';
import { BannersService } from '../_services/banners.service';

import { get, set } from '../_services/storage';
import { BandasService } from '../_services/bandas.service';


@Component({
  selector: 'listabandas',
  templateUrl: 'listabandas.template.html',
  styleUrls: ['listabandas.template.css'],
})
export class ListaBandasComponent implements OnInit, OnDestroy {

  testValue: any;
  bandas: any = [];
  banner: any;

  zonas: any = [
    {
      nombre: 'Jaén',
      bandas: [],
      id: 1
    },
    {
      nombre: 'Provincia',
      bandas: [],
      id: 2
    },
    {
      nombre: 'Andalucía',
      bandas: [],
      id: 3
    },
    {
      nombre: 'España',
      bandas: [],
      id: 4
    }
  ];

  version: any = 0;

  constructor(
    private router: Router,
    private bandasService: BandasService,
    private bannersService: BannersService,
    //private nativePageTransitions: NativePageTransitions,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {

    console.log("Inicio");
    this.componentDidLoad();

  }

  async componentDidLoad() {
    
    this.getBandas();
      
  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    
    this.getBanner();
    
  }

  getBanner(){
    this.bannersService.getBanner('movil_guia_bandas').subscribe(
      data => {
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }

  getBandas(event = null){
    this.bandas = [];
    this.bandasService.getBandas().subscribe(
      data => {
        var response: any = data;
        this.bandas = response.bandas;
        set("bandas", this.bandas);
        this.formaBandas();
        if (event){
          event.target.complete();
        }
      }
    )
  }

  getBanda(banda:any){
    
    let navigationExtras: NavigationExtras = { state: {banda: banda} };
    this.router.navigate(['banda',banda.id], navigationExtras);
    
  }

  formaBandas(){
    for (let zona of this.zonas){
      zona.bandas = []; 
      for (let banda of this.bandas){
        if (banda.zona == zona.id){
          zona.bandas.push(banda);
        }
      }
    }
  }
  
  getTipo(banda: any){
    if (banda.tipo == 1){
      return "Agrupación musical";
    }
    if (banda.tipo == 2){
      return "Cornetas y Tambores";
    }
    if (banda.tipo == 3){
      return "Banda de música";
    }
    if (banda.tipo == 4){
      return "Música de capilla";
    }
  }
  
  getDark(img){
    if (img){
      return img += '_dark';
    }else{
      return img;
    }
  }

}
