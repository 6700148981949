import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { CofradiasService } from '../_services/cofradias.service';
import { NoticiasService } from '../_services/noticias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { get, set } from '../_services/storage';

@Component({
  selector: 'detallecofradia',
  templateUrl: 'detallecofradia.template.html',
  styleUrls: ['detallecofradia.template.css'],
})
export class DetalleCofradiaComponent implements OnInit, OnDestroy {

  cofradia: any;
  cofradias: any;
  id_cofradia: any;

  verdetalles: boolean = false;
  
  bandas: any = [];
  imagineria: any = [];

  constructor(
    private router: Router,
    //private nativePageTransitions: NativePageTransitions,
    private cofradiasService: CofradiasService,
    private noticiasService: NoticiasService,
    private activatedRoute: ActivatedRoute) {

    this.id_cofradia = activatedRoute.snapshot.params["id_cofradia"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.cofradia= this.router.getCurrentNavigation().extras.state.cofradia;
      }
    });
    
  }

  ngOnInit() {

    this.init();

  }

  async init(){
    
    if (!this.cofradia){
      this.cofradias = await get("cofradias");
      if (this.cofradias === null){
        this.cofradias = [];
        this.getCofradias();
      }else{
        this.initCofradia();
      }

      console.log(this.cofradias);
    }
  }

  getCofradias(){
    this.cofradias = [];
    this.cofradiasService.getCofradias().subscribe(
      data => {
        var response: any = data;
        this.cofradias = response.cofradias;
        set("cofradias", this.cofradias);
        this.initCofradia();
      }
    )
  }

  initCofradia(){
    console.log("entro");
    for (let cofradia of this.cofradias){
      if (cofradia.id_cofradia == this.id_cofradia){
        this.cofradia = cofradia;
        this.getBandas();
        this.getImagineria();
      }
    }
  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    if (this.cofradia){
      this.getBandas();
      this.getImagineria();
    }
  }

  back(){
    /*let options: NativeTransitionOptions = {
      direction: 'right',
      duration: 400,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    this.router.navigate(['/cofradias/']);
  }

  verMapa(){
    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    let navigationExtras: NavigationExtras = { state: {cofradia: this.cofradia, back: null} };
    this.router.navigate(['cofradia', 'mapa',this.id_cofradia], navigationExtras);
  }

  getImagineria(){
    this.cofradiasService.getImagineria(this.cofradia.id_cofradia).subscribe(
      data => {
        var response: any = data;
        this.imagineria = response.imagineria;
      }
    )
  }
  
  getBandas(){
    this.cofradiasService.getBandas(this.cofradia.id_cofradia).subscribe(
      data => {
        var response: any = data;
        this.bandas = response.bandas;
      }
    )
  }
  
  getBanda(banda:any){
    
    let navigationExtras: NavigationExtras = { state: {banda: banda, cofradia: this.cofradia} };
    this.router.navigate(['banda',banda.id], navigationExtras);
    
  }

  getImagen(imagen:any){
    let navigationExtras: NavigationExtras = { state: {imagen: imagen, cofradia: this.cofradia} };
    this.router.navigate(['imagen',imagen.id], navigationExtras);
  }
  
  getTipo(banda: any){
    if (banda.tipo == 1){
      return "Agrupación musical";
    }
    if (banda.tipo == 2){
      return "Cornetas y Tambores";
    }
    if (banda.tipo == 3){
      return "Banda de música";
    }
    if (banda.tipo == 4){
      return "Música de capilla";
    }
  }
  
  getDark(img){
    if (img){
      return img.replace('.png', '_dark.png');
    }else{
      return img;
    }
  }
  
  errorReporting(){
    
    console.log("voy a error");
    
    let navigationExtras: NavigationExtras = { state: {from: this.router.url} };
    this.router.navigate(['error'], navigationExtras);
    
  }

}
