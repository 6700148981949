import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()

export class ErrorService {
    
    constructor(private http: HttpClient) { }

    postError(error: any){
        
        console.log("post error");
        var url = 'http://dev.pasionenjaen.com/error';
        return this.http.post(url, error,{
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
        });
    }


}