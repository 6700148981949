import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { BandasService } from '../_services/bandas.service';
import { NoticiasService } from '../_services/noticias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';

@Component({
  selector: 'detalleimagen',
  templateUrl: 'detalleimagen.template.html',
  styleUrls: ['detalleimagen.template.css'],
})
export class DetalleImagenComponent implements OnInit, OnDestroy {

  imagen: any = {};
  id_imagen: any;
  
  cofradia: any = {};

  verdetalles: boolean = false;
  
  cofradias: any = [];
  cofradiasfuerajaen: any = [];

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private bandasService: BandasService,
    private activatedRoute: ActivatedRoute) {

    this.id_imagen = activatedRoute.snapshot.params["id_imagen"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.imagen= this.router.getCurrentNavigation().extras.state.imagen;
        this.cofradia = this.router.getCurrentNavigation().extras.state.cofradia;
      }
    });
    
  }

  ngOnInit() {
    console.log(this.imagen);
  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    
  }

  back(){
    
  }

}
