import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../../_services/noticias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { RadioService } from 'src/app/_services/radio.service';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'player',
  templateUrl: 'player.template.html',
  styleUrls: ['player.template.css'],
})
export class PlayerComponent implements OnInit, OnDestroy {

  programa: any = {};
  id_programa: any;

  backurl: any;

  file: MediaObject;

  timeout: any;

  audiosub = new Subscription;
  programasub = new Subscription;
  audio: any;

  nuevo: any;

  playing: boolean = false;
  pauseing: boolean = false;

  duracion: any;
  posicion: any;

  puedo: boolean = false;

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private radioService: RadioService,
    //private nativePageTransitions: NativePageTransitions,
    private backgroundMode: BackgroundMode,
    private globalService: GlobalService,
    private media: Media,
    private activatedRoute: ActivatedRoute) {

    this.id_programa = activatedRoute.snapshot.params["id"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.programa= this.router.getCurrentNavigation().extras.state.programa;
        this.nuevo= this.router.getCurrentNavigation().extras.state.nuevo;
        this.backurl= this.router.getCurrentNavigation().extras.state.back;
      }
    });
    
  }

  ngOnInit() {

    console.log("Player");
    console.log(this.programa);

    if(!this.nuevo){
      this.audiosub = this.globalService.audioEmitter.subscribe(
        (mode) => {
          this.audio = mode;
          if (this.audio){
            this.file = this.audio;
            this.programasub = this.globalService.programaEmitter.subscribe(
              (mode) => {
                this.programa = mode;
                this.playing = true;
                this.pauseing = false;
                console.log(this.file);
                this.timeout = setTimeout( () => this.getSeek(), 500);
              }
            );
          }else{
            //this.initAudio();
          }
        }
      )
    }else{
      //this.initAudio();
    }

  }

  initAudio(){

    this.file = this.media.create('http://www.pasionenjaen.com/radio/'+this.programa.audio+'.mp3');

    this.file.onStatusUpdate.subscribe(status => console.log(status)); // fires when file status changes

    this.file.onSuccess.subscribe(() => console.log('Action is successful'));

    this.file.onError.subscribe(error => console.log('Error!', error));

    this.globalService.setAudio(this.file);
    this.globalService.setPrograma(this.programa);

    // play the file
    this.file.play();

    // get file duration
    let duration = this.file.getDuration();
    console.log(duration);

    this.backgroundMode.enable();
    this.backgroundMode.on("activate").subscribe(()=>{
      this.play();  
   });

    this.timeout = setTimeout( () => this.getSeek(), 500);

    this.playing = true;

  }

  ngOnDestroy() {
      clearTimeout(this.timeout);
  }

  ngAfterViewInit() {
    
  }

  getSeek(){

    let duration = this.file.getDuration();
    console.log(duration);
    this.duracion = duration;
    // get current playback position
    this.file.getCurrentPosition().then((position) => {
      console.log(position);
      this.posicion = position;
      clearTimeout(this.timeout);
      this.timeout = setTimeout( () => this.getSeek(), 500);
    });

  }

  back(){
    /*let options: NativeTransitionOptions = {
      direction: 'right',
      duration: 400,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    this.router.navigate(['/'+this.backurl]);
  }

  play(){

    if (!this.pauseing){
      this.initAudio();
    }else{
      this.file.play();
      this.playing = true;
      this.pauseing = false;
      this.globalService.setAudio(this.file);
      this.globalService.setPrograma(this.programa);
    }
    
  }

  clickPlay(){

  }

  pause(){
    // pause the file
    this.file.pause();
    this.pauseing = true;
    this.playing = false;
    if (this.audiosub){
      this.audiosub.unsubscribe();
    }
    if (this.programasub){
      this.programasub.unsubscribe();
    }
    this.globalService.setAudio(null);
    this.globalService.setPrograma(null);

  }

  reward(){
    if(this.playing){
      var aux = this.posicion - 15;
      if (aux < 0){
        aux = 0;
      }
      this.file.seekTo(aux * 1000);
    }
  }

  forward(){
    if(this.playing){
      var aux = this.posicion + 15;
      if (aux > this.duracion){
        aux = this.duracion;
      }
      this.file.seekTo(aux * 1000);
    }
  }

  barra(event: any){
    if (this.puedo){
      console.log(event);
      this.file.seekTo(this.posicion * 1000);
    }
  }

}
