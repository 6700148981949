import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../_services/noticias.service';

import { BannersService } from '../_services/banners.service';
import { EventosService } from '../_services/eventos.service';
import { CofradiasService } from '../_services/cofradias.service';

@Component({
  selector: 'agenda',
  templateUrl: 'agenda.template.html',
  styleUrls: ['agenda.template.css'],
})
export class AgendaComponent implements OnInit, OnDestroy {

  eventos: any = [];
  banner: any;
  
  dias: any = "";

  constructor(
    private router: Router,
    private eventosService: EventosService,
    private bannersService: BannersService,
    private cofradiasService: CofradiasService,
    private activatedRoute: ActivatedRoute) {
    
  }

  ngOnInit() {

    console.log("Agenda");
    this.getEventos();

  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    this.getBanner();
    this.getDias();
  }
  
  getDias(){
    this.cofradiasService.getDias().subscribe(
      data => {
        var request: any = data;
        this.dias = request.dias;
        console.log(this.dias);
      }
    )
  }

  getBanner(){
    this.bannersService.getBanner('movil_eventos').subscribe(
      data => {
        console.log(data);
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }

  getEventos(){
    this.eventosService.getEventos().subscribe(
      data => {
        console.log(data);
        var response: any = data;
        this.eventos = response.eventos;
      }
    )
  }

  recargarEventos(event){

    this.eventosService.getEventos().subscribe(
      data => {
        console.log(data);
        var response: any = data;
        this.eventos = response.eventos;
        event.target.complete();
      }
    )
    
  }

  getEvento(evento:any){
    console.log(evento);

    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    let navigationExtras: NavigationExtras = { state: {evento: evento, back: 'agenda'} };
    this.router.navigate(['agenda','detalle'], navigationExtras);
    
  }

}
