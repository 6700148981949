import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { CofradiasService } from '../_services/cofradias.service';
import { BannersService } from '../_services/banners.service';

import { get, set } from '../_services/storage';
import { RadioService } from '../_services/radio.service';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'listaprogramas',
  templateUrl: 'listaprogramas.template.html',
  styleUrls: ['listaprogramas.template.css'],
})
export class ListaProgramasComponent implements OnInit, OnDestroy {

  programasub = new Subscription;
  audiosub = new Subscription;
  audio: any;
  programa: any;

  programas: any = [];
  categorias: any = [];
  categoria: any = {categoria: 'programa', nombre: "Programa"};
  banner: any;
  
  temporadas: any = [];

  constructor(
    private router: Router,
    private radioService: RadioService,
    private bannersService: BannersService,
    private globalService: GlobalService,
    //private nativePageTransitions: NativePageTransitions,
    private activatedRoute: ActivatedRoute) {

    this.audiosub = this.globalService.audioEmitter.subscribe(
      (mode) => {
        if (mode !== null){
          this.audio = mode;
          console.log(this.audio);
        }else{
          this.audio = null;
        }
      }
    )

    this.programasub = this.globalService.programaEmitter.subscribe(
      (mode) => {
        if (mode !== null){
          this.programa = mode;
          console.log(this.programa);
        }else{
          this.programa = null;
        }
      }
    );
    
  }

  ngOnInit() {

    console.log("Inicio");
    this.getCategorias();

  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    
    this.getBanner()
  }

  getBanner(){
    this.bannersService.getBanner('movil_noticias').subscribe(
      data => {
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }

  getCategorias(){
    this.programas = [];
    this.radioService.getCategorias().subscribe(
      data => {
        var response: any = data;
        this.categorias = response.programas;
        for (let categoria of this.categorias){
          if(categoria.categoria == 'programa'){
            this.categoria = categoria;
          }
        }
        this.getProgramas();
      }
    )
  }

  cambioCategoria(){
    this.getProgramas();
  }

  getProgramas(){
    this.programas = [];
    this.radioService.getProgramas(this.categoria.categoria).subscribe(
      data => {
        var response: any = data;
        this.programas = response.programas;
        this.temporadas = [];
        this.formatTemporadas();
      }
    )
  }
  
  formatTemporadas(){
    for (let programa of this.programas){
      if (programa.temporada){
        let encontrado = 0;
        for (let temporada of this.temporadas){
          if (temporada.numero == programa.temporada){
            encontrado = 1;
          }
        }
        if (encontrado == 0){
          var aux: any = {
            numero: programa.temporada,
            programas: []
          }
          this.temporadas.push(aux);
        }
      }
    }
    
    for (let temporada of this.temporadas){
      for (let programa of this.programas){
        if (programa.temporada == temporada.numero){
          temporada.programas.push(programa);
        }
      }
    }
    
    console.log(this.temporadas);
    
  }

  recargarProgramas(event){

    this.radioService.getProgramas('programa').subscribe(
      data => {
        var response: any = data;
        this.programas = response.programas;
        this.temporadas = [];
        this.formatTemporadas();
        event.target.complete();
      }
    )
    
  }

  getPrograma(programa:any){
    
    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    /*let navigationExtras: NavigationExtras = { state: {programa: programa, nuevo: true, back: 'radio'} };
    this.router.navigate(['player',programa.id], navigationExtras);*/

    window.location.href = 'https://radio.pasionenjaen.com/programa/'+programa.id;
    
  }

  irPlayer(){
    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    let navigationExtras: NavigationExtras = { state: {programa: this.programa, nuevo: false, back: 'radio'} };
    this.router.navigate(['player',this.programa.id], navigationExtras);
  }

}
