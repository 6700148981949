import { Injectable } from '@angular/core'
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable()

export class GlobalService {

    audio: any = [];

    private _audio: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public audioEmitter: Observable<any> = this._audio.asObservable();

    private _programa: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public programaEmitter: Observable<any> = this._programa.asObservable();

    constructor(private http: HttpClient
              ) {
              }

    setAudio(au: any) {

      this.audio = au;
      this._audio.next(au);

    }

    setPrograma(pro: any){
      this._programa.next(pro);
    }


}
