import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../_services/noticias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { BannersService } from '../_services/banners.service';

import { get, set } from '../_services/storage';

@Component({
  selector: 'timeline',
  templateUrl: 'timeline.template.html',
  styleUrls: ['timeline.template.css'],
})
export class TimelineComponent implements OnInit, OnDestroy {

  timeline: any;
  id_cofradia: any
  banner: any;
  
  lastseen: any;

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private bannersService: BannersService,
    //private nativePageTransitions: NativePageTransitions,
    private activatedRoute: ActivatedRoute) {

    this.id_cofradia = activatedRoute.snapshot.params["id_cofradia"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.timeline= this.router.getCurrentNavigation().extras.state.timeline;
      }
    });
    
  }

  ngOnInit() {

    console.log("Noticias");
    this.getMostRecent();
  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    this.getBanner();
  }
  
  async getMostRecent(){
    this.lastseen = await get("timeline_"+this.timeline.cofradia.id);
    console.log(this.lastseen);
    this.init();
  }

  async init(){
    await this.setMostRecent(this.timeline);
  }

  async setMostRecent(timeline: any){
    return set("timeline_"+timeline.cofradia.id, timeline);
  }

  getBanner(){
    this.bannersService.getBanner('movil_noticias').subscribe(
      data => {
        console.log(data);
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }

  getNoticia(noticia:any){
    console.log(noticia);
    let navigationExtras: NavigationExtras = { state: {noticia: noticia, back: 'timeline/'+this.timeline.cofradia.id } };
    this.router.navigate(['noticia',noticia.id], navigationExtras);
  }

  getDark(img){
    if (img){
      return img.replace('.png', '_dark.png');
    }else{
      return img;
    }
  }

  getEvento(evento:any){
    console.log(evento);
    let navigationExtras: NavigationExtras = { state: {evento: evento, back: 'timeline/'+this.timeline.cofradia.id } };
    this.router.navigate(['agenda','detalle'], navigationExtras);
    
  }
  
  goToCofradia(){
    let navigationExtras: NavigationExtras = { state: {cofradia: null} };
    this.router.navigate(['cofradia',this.id_cofradia], navigationExtras);
  }

}
