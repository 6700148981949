import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'informacion',
  templateUrl: 'informacion.template.html',
  styleUrls: ['informacion.template.css'],
})
export class InformacionComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    
  }

}
