import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()

export class BandasService {

    constructor(private http: HttpClient) { }

    getBandas(){
        console.log("pido bandas");
        var url = 'http://dev.pasionenjaen.com/bandas-io/';
        return this.http.get(url);

    }
    
    getCofradias(id_banda: any){
        console.log("pido cofradias");
        var url = 'http://dev.pasionenjaen.com/banda/'+id_banda+'/cofradias-jaen/';
        return this.http.get(url);

    }
    
    getCofradiasFueraJaen(id_banda: any){
        console.log("pido cofradias");
        var url = 'http://dev.pasionenjaen.com/banda/'+id_banda+'/cofradias-fuera-jaen/';
        return this.http.get(url);

    }


}