import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { CofradiasService } from '../_services/cofradias.service';
import { BannersService } from '../_services/banners.service';

import { get, set } from '../_services/storage';


@Component({
  selector: 'listacofradias',
  templateUrl: 'listacofradias.template.html',
  styleUrls: ['listacofradias.template.css'],
})
export class ListaCofradiasComponent implements OnInit, OnDestroy {

  testValue: any;
  cofradias: any = [];
  banner: any;

  dias: any = [
    {
      nombre: 'Domingo de Ramos',
      cofradias: [],
      id: 0
    },
    {
      nombre: 'Lunes Santo',
      cofradias: [],
      id: 1
    },
    {
      nombre: 'Martes Santo',
      cofradias: [],
      id: 2
    },
    {
      nombre: 'Miércoles Santo',
      cofradias: [],
      id: 3
    },
    {
      nombre: 'Jueves Santo',
      cofradias: [],
      id: 4
    },
    {
      nombre: 'Madrugada',
      cofradias: [],
      id: 5
    },
    {
      nombre: 'Viernes Santo',
      cofradias: [],
      id: 6
    },
    {
      nombre: 'Domingo de Resurrección',
      cofradias: [],
      id: 7
    }
  ];

  version: any = 0;

  constructor(
    private router: Router,
    private cofradiasService: CofradiasService,
    private bannersService: BannersService,
    //private nativePageTransitions: NativePageTransitions,
    private activatedRoute: ActivatedRoute) {

    //this.id_empresa = activatedRoute.snapshot.params["id_empresa"];
    
  }

  ngOnInit() {

    console.log("Inicio");
    this.componentDidLoad();

  }

  async componentDidLoad() {
    this.cofradias = await get("cofradias");
    if (this.cofradias === null){
      this.cofradias = [];
      this.getCofradias();
    }else{
      this.formatCofradias();
    }

    console.log(this.cofradias);
  }

  versionCofradias(){
    // tenemos que ver la version de los datos de las cofradias para recargarlas o no
    this.cofradiasService.getVersionDatos().subscribe(
      data => {
        var response: any = data;

        this.comprobarVersion(response);

      }
    ) 

  }

  async comprobarVersion(response: any){
    this.version = await get("version_cofradias");
    console.log(this.version);
    console.log(response.version);
    if (this.version === null || this.version !== response.version){
      this.version = response.version;
      set("version_cofradias", this.version);
      
      this.getCofradias();
    }else{
      if (this.version === response.version){
        this.formatCofradias();
      }
    }
  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    
    this.getBanner();
    this.versionCofradias();
    
  }

  getBanner(){
    this.bannersService.getBanner('movil_guia').subscribe(
      data => {
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }

  getCofradias(){
    this.cofradias = [];
    this.cofradiasService.getCofradias().subscribe(
      data => {
        var response: any = data;
        this.cofradias = response.cofradias;
        set("cofradias", this.cofradias);
        this.formatCofradias();
      }
    )
  }

  recargarCofradias(event){

    this.cofradiasService.getCofradias().subscribe(
      data => {
        var response: any = data;
        this.cofradias = response.cofradias;
        set("cofradias", this.cofradias);
        this.formatCofradias();
        event.target.complete();
      }
    )
    
  }

  getCofradia(cofradia:any){
    
    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    let navigationExtras: NavigationExtras = { state: {cofradia: cofradia} };
    this.router.navigate(['cofradia',cofradia.id_cofradia], navigationExtras);
    
  }

  formatCofradias(){
    for (let dia of this.dias){
      dia.cofradias = []; 
      for (let cofradia of this.cofradias){
        cofradia.suspendida = 0;
        if (cofradia.dia_procesion == dia.id){
          dia.cofradias.push(cofradia);
          this.getCofradiasSuspendidas();
        }
      }
    }
  }

  getCofradiasSuspendidas(){
    this.cofradiasService.getCofradiasSuspendidas().subscribe(
      data => {
        var response: any = data;
        var suspendidas = response.suspendidas;
        for (let dia of this.dias){
          for (let cofradia of dia.cofradias){
            for (let cof of suspendidas){
              if (cofradia.id_cofradia == cof.id_cofradia){
                cofradia.suspendida = 1;
              }
            }
          }
        }
      }
    )
  }
  
  getDark(img){
    if (img){
      return img.replace('.png', '_dark.png');
    }else{
      return img;
    }
  }

}
