import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Inicio',
      url: '/inicio',
      icon: 'home'
    },
    {
      title: 'Noticias',
      url: '/noticias',
      icon: 'newspaper'
    },
    {
      title: 'Agenda',
      url: '/agenda',
      icon: 'calendar'
    },
    {
      title: 'Cofradías',
      url: '/cofradias',
      icon: 'book'
    },
    {
      title: 'Bandas',
      url: '/bandas',
      icon: 'musical-notes'
    },
    {
      title: 'Radio',
      url: '/radio',
      icon: 'radio'
    },
    {
      title: 'Información',
      url: '/informacion',
      icon: 'information'
    }
  ];
  public labels = ['Domingo de Ramos', 'Lunes Santo', 'Martes Santo', 'Miércoles Santo', 'Jueves Santo', 'Madrugada', 'Viernes Santo', 'Domingo de Resurrección'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private backgroundMode: BackgroundMode,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      //this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.backgroundMode.enable();
  }
}
