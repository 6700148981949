import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()

export class EventosService {

    constructor(private http: HttpClient) { }

    getEventos(){

        var url = 'http://dev.pasionenjaen.com/eventos/ios/';
        return this.http.get(url);

    }

}