import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from '../_services/error.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'errorreporting',
  templateUrl: 'errorreporting.template.html',
  styleUrls: ['errorreporting.template.css'],
})
export class ErrorReportingComponent implements OnInit, OnDestroy {
  
  from: any = '';
  
  email: any = '';
  error: any = '';
  
  sended: boolean = false;

  constructor(
    private router: Router,
    private errorService: ErrorService,
    public alertController: AlertController,
    private activatedRoute: ActivatedRoute) {
      
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.from= this.router.getCurrentNavigation().extras.state.from;
      }
    });

  }

  ngOnInit() {

    console.log(this.from);
    
  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    this.sended = false;
  }
  
  postError(){
    
    var error: any = {
      email: this.email,
      error: this.error,
      from: this.from
    }
    
    if (!this.error || this.error == ''){
      this.presentAlert();
    }else{
    
      this.errorService.postError(error).subscribe(
        data => {
          console.log(data);
          this.sended = true;
        }
      )
    
    }
    
  }
  
  goBack(){
    this.router.navigate(this.from);
  }
  
  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atención',
      subHeader: 'Faltan datos por rellenar',
      message: 'Debes rellenar al menos el error detectado',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

}
