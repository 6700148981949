import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { NoticiaComponent } from './noticia/noticia.component';
import { ListaCofradiasComponent } from './listacofradias/listacofradias.component';
import { DetalleCofradiaComponent } from './detallecofradia/detallecofradia.component';
import { MapaComponent } from './detallecofradia/mapa/mapa.component';
import { ListaProgramasComponent } from './listaprogramas/listaprogramas.component';
import { PlayerComponent } from './listaprogramas/player/player.component';
import { AgendaComponent } from './agenda/agenda.component';
import { EventoComponent } from './evento/evento.component';
import { DiaSemanaSantaComponent } from './diasemanasanta/diasemanasanta.component';
import { InformacionComponent } from './informacion/informacion.component';
import { MultimapaComponent } from './diasemanasanta/multimapa/multimapa.component';
import { ListaBandasComponent } from './listabandas/listabandas.component';
import { DetalleBandaComponent } from './detallebanda/detallebanda.component';
import { ErrorReportingComponent } from './errorreporting/errorreporting.component';
import { TimelineComponent } from './timeline/timeline.component';
import { DetalleImagenComponent } from './detalleimagen/detalleimagen.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  { path: 'inicio', component: InicioComponent},
  { path: 'noticias', component: NoticiasComponent},
  { path: 'noticia/:id', component: NoticiaComponent},
  { path: 'cofradias', component: ListaCofradiasComponent},
  { path: 'bandas', component: ListaBandasComponent},
  { path: 'banda/:id_banda', component: DetalleBandaComponent},
  { path: 'imagen/:id_imagen', component: DetalleImagenComponent},
  { path: 'cofradia/:id_cofradia', component: DetalleCofradiaComponent},
  { path: 'cofradia/mapa/:id_cofradia', component: MapaComponent},
  { path: 'radio', component: ListaProgramasComponent},
  { path: 'player/:id', component: PlayerComponent},
  { path: 'agenda', component: AgendaComponent},
  { path: 'agenda/detalle', component: EventoComponent},
  { path: 'dia/:dia', component: DiaSemanaSantaComponent},
  { path: 'dia/mapa/:dia', component: MultimapaComponent},
  { path: 'informacion', component: InformacionComponent},
  { path: 'error', component: ErrorReportingComponent},
  { path: 'timeline/:id_cofradia', component: TimelineComponent},
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
