import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()

export class WaypointsService {

    constructor(private http: HttpClient) { }

    getWaypoints(id_cofradia: any){
        console.log("Pedimos waypoints");
        var url = 'http://dev.pasionenjaen.com/waypoints/'+id_cofradia+'/';
        return this.http.get(url);

    }

    getVersionWaypointsCofradia(id_cofradia: any){
        var url = 'http://dev.pasionenjaen.com/versiones/cofradias/'+id_cofradia;
        return this.http.get(url);
    }

    getPosicionCofradia(id_cofradia: any){
        var url = 'http://dev.pasionenjaen.com/gps/cofradia/'+id_cofradia;
        return this.http.get(url);
    }

    getPosicionesCofradiasDia(dia: any){
        var url = 'http://dev.pasionenjaen.com/gps/dia/'+dia;
        return this.http.get(url);
    }

    getPosicionCofradiasDia(id_dia: any){
        var url = 'http://admin.pasionenjaen.com/json/cargarGPSCofradias.php?dia='+id_dia;
        return this.http.get(url);
    }
    
    setUserLocation(latitud: any, longitud: any, terminal: any){
        var url = 'http://dev.pasionenjaen.com/GPSLocatorUser.php?terminal='+terminal+'&latitud='+latitud+'&longitud='+longitud;
        return this.http.get(url);
    }

}