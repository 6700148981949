import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../_services/noticias.service';
import { CofradiasService } from '../_services/cofradias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { RadioService } from '../_services/radio.service';
import { EventosService } from '../_services/eventos.service';
import { BannersService } from '../_services/banners.service';

import { get, set } from '../_services/storage';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed } from '@capacitor/core';

const { PushNotifications } = Plugins;

@Component({
  selector: 'inicio',
  templateUrl: 'inicio.template.html',
  styleUrls: ['inicio.template.css'],
})
export class InicioComponent implements OnInit, OnDestroy {

  dias: any = '';
  noticias: any = [];
  programas: any = [];
  eventos: any = [];
  banner: any;
  timeline: any;
  
  randimage: any;
  randimagebanda: any;
  randbandas: any = ['banda_amargura_cabe', 'banda_blanconajera_cabe', 'banda_despojado_cabe', 'banda_estrella_cabe', 'banda_expiracion_cabe', 'banda_juvenilestrella_cabe', 'banda_municipal_cabe', 'banda_sinfonica_cabe'];

  verdia: any = null;

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private cofradiasService: CofradiasService,
    private radioService: RadioService,
    private eventosService: EventosService,
    private bannersService: BannersService,
    //private nativePageTransitions: NativePageTransitions,
    private activatedRoute: ActivatedRoute) {
    
  }

  ngOnInit() {

    /*
    PushNotifications.register();

    PushNotifications.addListener('registration', 
      (token: PushNotificationToken) => {
        //alert('Push registration success, token: ' + token.value);
      }
    );

    PushNotifications.addListener('registrationError', 
      (error: any) => {
        //alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    PushNotifications.addListener('pushNotificationReceived', 
      (notification: PushNotification) => {
        //alert('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed', 
      (notification: PushNotificationActionPerformed) => {
        //alert('Push action performed: ' + JSON.stringify(notification));
      }
    );*/

  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    this.getDias();
    this.getBanner();
    this.getNoticias();
    this.getProgramas();
    this.getEventos();
    this.getTimeline();
    
    this.randimage = this.rand(1,20);
    this.randimagebanda = this.randbandas[this.rand(0,this.randbandas.length - 1)];
  }

  recargarInicio(event){

    this.getDias();
    this.getBanner();
    this.getNoticias();
    this.getProgramas();
    this.getEventos();
    this.getTimeline();

    setTimeout( ()=> event.target.complete(), 200);
    
  }

  getBanner(){
    this.bannersService.getBanner('movil_inicio').subscribe(
      data => {
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }


  getDias(){
    this.cofradiasService.getDias().subscribe(
      data => {
        var request: any = data;
        this.dias = request.dias;
        var id = request.id;
        var signo = request.signo;
        if (id == 0){
          this.verdia = 0;
        }
        if (id == 1 && signo == '-'){ //lunes santo
          this.verdia = 1;
        }
        if (id == 2 && signo == '-'){ //martes santo
          this.verdia = 2;
        }
        if (id == 3 && signo == '-'){ //miercoles santo
          this.verdia = 3;
        }
        if (id == 4 && signo == '-'){ //jueves santo
          this.verdia = 4;
        }
        if (id == 5 && signo == '-'){ //viernes santo
          this.verdia = 5;
        }
        if (id == 6 && signo == '-'){ //sabado santo
          this.verdia = null;
        }
        if (id == 7 && signo == '-'){ //domingo resurreccion
          this.verdia = 7;
        }

        console.log(this.dias);
      }
    )
  }

  getNoticias(){
    this.noticiasService.getUltimaNoticia().subscribe(
      data => {
        console.log(data);
        var response: any = data;
        this.noticias = response.noticias;
      }
    )
  }

  getTimeline(){
    this.cofradiasService.getTimeline().subscribe(
      data => {
        console.log(data);
        var response: any = data;
        this.timeline = response.timeline;
        this.timeline.sort((a, b) => parseFloat(b.most_recent) - parseFloat(a.most_recent));
        this.formatTimeline();
      }
    )
  }

  async formatTimeline(){
    for(let timeline of this.timeline){
      var stored_timeline = await this.getMostRecent(timeline);
      if (stored_timeline){
        if (stored_timeline.most_recent < timeline.most_recent){
          timeline.new = true;
        }else{
          timeline.new = false;
        }
      }else{
        timeline.new = true;
      }
    }
    console.log(this.timeline);
  }

  async getMostRecent(timeline: any){
    return get("timeline_"+timeline.cofradia.id);
  }

  getNoticia(noticia:any){
    console.log(noticia);

    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    let navigationExtras: NavigationExtras = { state: {noticia: noticia, back: 'inicio'} };
    this.router.navigate(['noticia',noticia.id], navigationExtras);
    
  }

  getProgramas(){
    this.programas = [];
    this.radioService.getProgramas('programa').subscribe(
      data => {
        var response: any = data;
        this.programas = response.programas;
      }
    )
  }

  getPrograma(programa:any){
    
    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    let navigationExtras: NavigationExtras = { state: {programa: programa, nuevo: true, back: 'inicio'} };
    //this.router.navigate(['player',programa.id], navigationExtras);
    this.router.navigate(['radio']);
    
  }

  getEventos(){
    this.eventosService.getEventos().subscribe(
      data => {
        var response: any = data;
        this.eventos = response.eventos;
      }
    )
  }

  irAgenda(){
    this.router.navigate(['agenda']);
  }

  getEvento(evento:any){
    console.log(evento);

    /*let options: NativeTransitionOptions = {
      direction: 'left',
      duration: 300,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    let navigationExtras: NavigationExtras = { state: {evento: evento, back: 'inicio'} };
    this.router.navigate(['agenda','detalle'], navigationExtras);
    
  }

  getDark(img){
    if (img){
      return img.replace('.png', '_dark.png');
    }else{
      return img;
    }
  }

  goToTimeline(timeline: any){
    timeline.new = false;
    let navigationExtras: NavigationExtras = { state: {timeline: timeline} };
    this.router.navigate(['timeline',timeline.cofradia.id], navigationExtras);
  }
  
  goToCofradias(){
    this.router.navigate(['cofradias']);
  }
  
  goToBandas(){
    this.router.navigate(['bandas']);
  }
  
  rand(min, max){
    return  Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
