import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../_services/noticias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@Component({
  selector: 'noticia',
  templateUrl: 'noticia.template.html',
  styleUrls: ['noticia.template.css'],
})
export class NoticiaComponent implements OnInit, OnDestroy {

  noticia: any = {};
  id_noticia: any;
  backurl: any;
  
  canemail: boolean = false;
  canfacebook: boolean = false;
  cantwitter: boolean = false;
  caninstagram: boolean = false;
  canwhatsapp: boolean = false;

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private socialSharing: SocialSharing,
    //private nativePageTransitions: NativePageTransitions,
    private activatedRoute: ActivatedRoute) {

    this.id_noticia = activatedRoute.snapshot.params["id"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.noticia= this.router.getCurrentNavigation().extras.state.noticia;
        this.backurl= this.router.getCurrentNavigation().extras.state.back;
      }
    });
    
  }

  ngOnInit() {

    console.log("Noticia");
    console.log(this.noticia);
    
    // Check if sharing via email is supported
    this.socialSharing.canShareViaEmail().then(() => {
      this.canemail = true;
    }).catch(() => {
      this.canemail = false;
    });
    
    this.socialSharing.canShareVia('facebook').then(() => {
      this.canfacebook = true;
    }).catch(() => {
      this.canfacebook = false;
    });
    
    this.socialSharing.canShareVia('twitter').then(() => {
      this.cantwitter = true;
    }).catch(() => {
      this.cantwitter = false;
    });
    
    this.socialSharing.canShareVia('instagram').then(() => {
      this.caninstagram = true;
    }).catch(() => {
      this.caninstagram = false;
    });
    
    this.socialSharing.canShareVia('whatsapp').then(() => {
      this.canwhatsapp = true;
    }).catch(() => {
      this.canwhatsapp = false;
    });

  }

  ngOnDestroy() {
      
  }

  ngAfterViewInit() {
    
  }

  back(){
    /*let options: NativeTransitionOptions = {
      direction: 'right',
      duration: 400,
      slowdownfactor: -1,
      iosdelay:100
  
    }
    this.nativePageTransitions.slide(options);*/
    this.router.navigate(['/'+this.backurl]);
  }
  
  ShareLink(parameter){
    const url = this.noticia.url
    const text = parameter+'\n'
    this.socialSharing.share(this.noticia.titulo, 'MEDIUM', null, url)
  }
  
  ShareWhatsapp(){
    this.socialSharing.shareViaWhatsApp(this.noticia.titulo, this.noticia.imagen_grande, this.noticia.url)
  }

  ShareFacebook(){
    this.socialSharing.shareViaFacebookWithPasteMessageHint(this.noticia.titulo, this.noticia.imagen_grande, null /* url */, 'Copia Pega!')
  }

  SendEmail(){
    this.socialSharing.shareViaEmail('text', 'subject', ['email@address.com'])
  }

  SendTwitter(){
    this.socialSharing.shareViaTwitter(this.noticia.titulo, this.noticia.imagen_grande, this.noticia.url)
  }

  SendInstagram(){
    this.socialSharing.shareViaInstagram(this.noticia.titulo, this.noticia.imagen_grande)
  }


}
