import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, Inject, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../../_services/noticias.service';
import { DOCUMENT } from '@angular/common';
import { LoadingController } from '@ionic/angular';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import { WaypointsService } from 'src/app/_services/waypoints.service';

import { get, set } from '../../_services/storage';
import { BannersService } from 'src/app/_services/banners.service';
import { CofradiasService } from 'src/app/_services/cofradias.service';
import {MapsService} from '../../_services/maps.service';


declare var google: any;

@Component({
  selector: 'mapa',
  templateUrl: 'mapa.template.html',
  styleUrls: ['mapa.template.css'],
})
export class MapaComponent implements OnInit, OnDestroy, AfterViewInit {

    showmap = false;

  cofradia: any = {};
  backurl: any = {};
  id_cofradia: any;

  banner: any;
  banners: any;
  pois: any = [];

  @ViewChild('Map', {static: false}) mapElement: ElementRef;

  map: any;
  infowindow: any;
  mapOptions: any;
  location = {lat: 37.766259, lng: -3.788640};
  markerOptions: any = {position: null, map: null, title: null};
  marker: any;
  apiKey: any = '';

  waypoints: any;
  version_waypoints: any;

  timeout: any;
  timeoutuser: any;

  waypointsArray: any = [];
  miposicionArray: any = [];
  markersBanners: any = [];
  markersPois: any = [];

  panmap: boolean = true;
  
  path: any;
  
  rand: any;

  loading: any;

  constructor(
    private router: Router,
    private loadingCtrl: LoadingController,
    private cofradiasService: CofradiasService,
    private noticiasService: NoticiasService,
    private bannersService: BannersService,
    public geolocation: Geolocation,
    public waypointsService: WaypointsService,
    private activatedRoute: ActivatedRoute,
    private mapsService: MapsService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
) {

    this.id_cofradia = activatedRoute.snapshot.params["id_cofradia"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.cofradia= this.router.getCurrentNavigation().extras.state.cofradia;
        this.backurl= this.router.getCurrentNavigation().extras.state.back;
      }
    });
    
    this.geolocation.getCurrentPosition().then((position) =>  {
        console.log(position);
    });

  }

  async ngOnInit() {

       this.loading = await this.loadingCtrl.create({
          message: 'Cargando...',
          spinner: 'circles',
      });

       this.loading.present();

  }

  loadApiMap(){
      this.mapsService.getApiKey().subscribe((data) => {
          const request: any = data;
          this.apiKey = request.apikey;
          const script = this._renderer2.createElement('script');
          script.id = 'googleMaps';
          script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&callback=Function.prototype';

          this._renderer2.appendChild(this._document.body, script);
          this.timeoutuser = setTimeout(() => this.init(), 1000);
      });
  }

  ngOnDestroy() {
      clearTimeout(this.timeout);
      clearTimeout(this.timeoutuser);
  }

  init(){

      this.loading.dismiss();
      this.showmap = true;

      this.getBannerSuperior();
      this.getVersionWaypointsCofradia();

      this.mapOptions = {
          center: this.location,
          zoom: 18,
          streetViewControl: false,
          mapTypeControl: false,
          styles: [{
              featureType: "poi",
              elementType: "labels",
              stylers: [{
                  visibility: "off"
              }]
          }]
      };

      setTimeout(() => {
          this.initMap();
      }, 10);
  }

  initMap(){

    if (typeof google === 'object' && typeof google.maps === 'object'){
      this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
      //this.markerOptions.position = this.location;
      //this.markerOptions.map = this.map;
      //this.markerOptions.title = 'My Location';
      //this.marker = new google.maps.Marker(this.markerOptions);
      this.infowindow = new google.maps.InfoWindow({
          content: ""
      });
      
      this.getBanners();
      this.getPois();
      
      var aux = Math.random() * (10 - 1) + 1;
      this.rand = 'ios-'+Date.now()+'-'+ aux+'-'+this.id_cofradia;
      
      this.timeoutuser = setTimeout(() => this.getCurrentCoordinates(), 5000);

    }
  }

  ngAfterViewInit() {

      if (document.getElementById('googleMaps')) {
          document.getElementById('googleMaps').remove();
      }

      setTimeout(() => {
          this.loadApiMap();
      },10);

  }

  getBannerSuperior() {
    this.bannersService.getBanner('banner_mapa').subscribe(
      data => {
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }


  getBanners() {
    this.banners = [];
    this.bannersService.getBanner('geoposicion').subscribe(
      data => {
        var response: any = data;
        this.banners = response.banners;
        this.mostrarBanners();
      }
    )
  }

  getPois() {
    this.cofradiasService.getPois(this.id_cofradia).subscribe(
      data => {
        var response: any = data;
        console.log(response.pois);
        this.pois = response.pois;
        this.mostrarPois();
      }
    )
  }

  back() {
    clearTimeout(this.timeout);
    if (!this.backurl){
      this.router.navigate(['/cofradia/',this.id_cofradia]);
    } else {
      this.router.navigate(['/dia/',this.backurl]);
    }
  }

  getWaypoints(){

    //miramos si hay guardados y si no los pedimos
    this.getStoreWaypoints();

  }

  async getStoreWaypoints() {
    this.waypoints = await get("waypoints_"+this.id_cofradia);

    if (this.waypoints === null || this.waypoints === undefined){
      this.waypointsService.getWaypoints(this.id_cofradia).subscribe(
        data => {
          var response: any = data;
          this.waypoints = response.waypoints;
          set("waypoints_"+this.id_cofradia, this.waypoints);
          this.pintarWaypoints();
        }
      )
    }else{
      this.pintarWaypoints();
    }

  }

  pintarWaypoints(){

    var recorrido = [];
    var mostrar= 1;
    for (let waypoint of this.waypoints){
      if(mostrar == 1){
        var obj: any = { lat: waypoint.latitud,lng: waypoint.longitud};
        recorrido.push(obj);
      }
      if (waypoint.sentido == 1){
        mostrar = 0;
      }
    }

    var recorridovuelta = [];

    for (let waypoint of this.waypoints){
      if (waypoint.sentido == 1){
        var obj: any = { lat: waypoint.latitud,lng: waypoint.longitud};
        recorridovuelta.push(obj);
      }
    }

    console.log(recorrido);

    var path = new google.maps.Polyline({
      path: recorrido,
      geodesic: true,
      strokeColor: '#ffae00',
      strokeOpacity: 1.0,
      strokeWeight: 5
    });
    
    path.setMap(this.map);

    var pathvuelta = new google.maps.Polyline({
      path: recorridovuelta,
      geodesic: true,
      strokeColor: '#17bbf8',
      strokeOpacity: 1.0,
      strokeWeight: 5
    });

    pathvuelta.setMap(this.map);

    console.log(this.waypoints);
    this.getPosicionCofradia();
  }

  getPosicionCofradia(){
    this.waypointsService.getPosicionCofradia(this.id_cofradia).subscribe(
      data => {
        console.log(data);

        if (this.waypointsArray) {
          for (let marker of this.waypointsArray) {
            marker.setMap(null);
          }
          this.waypointsArray = [];
        }
        if (this.path){
          this.path.setMap(null);
        }
        this.path = null;

        var puntos: any = data;
        for (let punto of puntos.g){
          
          var point = new google.maps.LatLng(punto.l,punto.n);

          var image = {
              url: "/assets/img/escudos/" + this.cofradia.escudo,
              scaledSize : new google.maps.Size(50, 50)
          };
          
          var html = this.cofradia.nombre_corto;
          if (punto.t == "10"){
            html += "";
          }
          
          if (punto.t != "1" && punto.t != "0" && punto.t){
            image = {
              url: "http://dev.pasionenjaen.com/img/markers/" + punto.t + "_" + this.id_cofradia + ".png",
              scaledSize : new google.maps.Size(50, 50)
            };
          }

          var marker = new google.maps.Marker({
            position: point,
            html: html,
            map: this.map,
            icon: image
          });

          var that = this;
          google.maps.event.addListener(marker, 'click', function() {
            that.infowindow.setContent(this.html);
            that.infowindow.open(that.map,this);
          });

          this.waypointsArray.push(marker);

          if (this.panmap){
            this.panmap = false;
            this.map.panTo(point);
          }
          
          if (punto.p && punto.s == 1){
            if (punto.p.length > 1){
              setTimeout(() => this.pintarSerpiente(punto.p), 0);
            }
          }

        }
        
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=> this.getPosicionCofradia(), 5000);
        
      }
    )
    
  }
  
  pintarSerpiente(waypoints: any){

    var serpiente = [];
    for (let waypoint of waypoints){
      
        var lat: number = +waypoint.l;
        var lon: number = +waypoint.n;
        var obj: any = { lat: lat, lng: lon};
        serpiente.push(obj);
    }

    console.log(serpiente);

    this.path = new google.maps.Polyline({
      path: serpiente,
      geodesic: true,
      strokeColor: '#7200ff',
      strokeOpacity: 1.0,
      strokeWeight: 8,
      zIndex: 200
    });
    
    this.path.setMap(this.map);
    
  }

  mostrarBanners(){

    if (this.markersBanners) {
      for (let marker of this.markersBanners) {
        marker.setMap(null);
      }
      this.markersBanners = [];
    }

    for (let banner of this.banners){
      
        var point = new google.maps.LatLng(banner.latitud,banner.longitud);

        /*var image = {
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            scaledSize : new google.maps.Size(50, 50)
        };*/
        var image = {
            url: "https://api.pasionenjaen.com/img/markers/markerAzul.svg",
            scaledSize : new google.maps.Size(50, 50)
        };
        
        var marker = new google.maps.Marker({
          position: point,
          html: '<a href="'+banner.url+'">'+banner.nombre+'</a><br><br>',
          map: this.map,
          icon: image
        });

        var that = this;
        google.maps.event.addListener(marker, 'click', function() {
          that.infowindow.setContent(this.html);
          that.infowindow.open(that.map,this);
        });

        this.markersBanners.push(marker);
      
    }

  }

  mostrarPois(){

    if (this.markersPois) {
      for (let marker of this.markersPois) {
        marker.setMap(null);
      }
      this.markersPois = [];
    }

    for (let poi of this.pois){
      
        var point = new google.maps.LatLng(poi.la,poi.lo);

        /*var image = {
            url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            scaledSize : new google.maps.Size(50, 50)
        };*/
        var image = {
            url: "https://api.pasionenjaen.com/img/markers/markerVerde.svg",
            scaledSize : new google.maps.Size(50, 50)
        };
        
        var marker = new google.maps.Marker({
          position: point,
          html: '<b>'+poi.no+'</b><br><br>'+poi.de+'<br><br>',
          map: this.map,
          icon: image
        });

        var that = this;
        google.maps.event.addListener(marker, 'click', function() {
          that.infowindow.setContent(this.html);
          that.infowindow.open(that.map,this);
        });

        this.markersPois.push(marker);
      
    }

  }

  getVersionWaypointsCofradia(){
    // tenemos que ver la version de los datos de las cofradias para recargarlas o no
    this.waypointsService.getVersionWaypointsCofradia(this.id_cofradia).subscribe(
      data => {
        var response: any = data;
        this.comprobarVersion(response);
      }
    ) 

  }
  
  // use geolocation to get user's device coordinates
  getCurrentCoordinates() {
    
    this.geolocation.getCurrentPosition().then((resp) => {
      const latitude = resp.coords.latitude;
      const longitude = resp.coords.longitude;
      this.waypointsService.setUserLocation(latitude, longitude, this.rand).subscribe(
        data => {

            if (this.miposicionArray) {
                for (const m of this.miposicionArray) {
                    m.setMap(null);
                }
                this.miposicionArray = [];
            }

            var point = new google.maps.LatLng(latitude,longitude);

            /*var image = {
                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                scaledSize : new google.maps.Size(50, 50)
            };*/
            var image = {
                url: "https://api.pasionenjaen.com/img/markers/marker.svg",
                scaledSize : new google.maps.Size(37, 50)
            };

            var marker = new google.maps.Marker({
                position: point,
                html: '<b>Tu posición</b><br><br>',
                map: this.map,
                icon: image
            });

            var that = this;
            google.maps.event.addListener(marker, 'click', function() {
                that.infowindow.setContent(this.html);
                that.infowindow.open(that.map,this);
            });

            this.miposicionArray.push(marker);
        }
      )
     }).catch((error) => {
       console.log('Error getting location', error);
     });
    
     this.timeoutuser = setTimeout(() => this.getCurrentCoordinates(), 5000);
    
  }

  async comprobarVersion(response) {
    this.version_waypoints = await get("version_waypoints_"+this.id_cofradia);

    if (this.version_waypoints === null || this.version_waypoints === undefined  || this.version_waypoints !== response.version){
      this.version_waypoints = response.version;
      set("version_waypoints_"+this.id_cofradia, this.version_waypoints);
      this.waypointsService.getWaypoints(this.id_cofradia).subscribe(
        data => {
          var response: any = data;
          this.waypoints = response.waypoints;
          set("waypoints_"+this.id_cofradia, this.waypoints);
          this.pintarWaypoints();
        }
      )
    }else{
      this.getWaypoints();
    }

  }

}
