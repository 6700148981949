import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MapsService {
    constructor(private http: HttpClient) {}

    getApiKey() {
        var url = 'http://dev.pasionenjaen.com/map_apikey/';
        return this.http.get(url);
    }
}
