import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, Inject} from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../../_services/noticias.service';
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import { WaypointsService } from 'src/app/_services/waypoints.service';

import { get, set } from '../../_services/storage';
import { BannersService } from 'src/app/_services/banners.service';
import {MapsService} from '../../_services/maps.service';
import {DOCUMENT} from '@angular/common';
import {LoadingController} from '@ionic/angular';


declare var google: any;

@Component({
  selector: 'multimapa',
  templateUrl: 'multimapa.template.html',
  styleUrls: ['multimapa.template.css'],
})
export class MultimapaComponent implements OnInit, OnDestroy {

  backurl: any = {};
  id_dia: any;

  banner: any;
  banners: any;

  @ViewChild('Map', {static: false}) mapElement: ElementRef;

  map: any;
  infowindow: any;
  mapOptions: any;
  location = {lat: 37.776259, lng: -3.788640};
  markerOptions: any = {position: null, map: null, title: null};
  marker: any;

  waypoints: any;
  version_waypoints: any;

  timeout: any;
  timeoutuser: any;

  waypointsArray: any = [];
  miposicionArray: any = [];
  markersBanners: any = [];

  rand: any;

  panmap: boolean = true;

  cofradias: any;

  path: any;
  pathvuelta: any;

  apiKey: any;

  loading: any;
  showmap = false;

  constructor(
    private router: Router,
    private loadingCtrl: LoadingController,
    private noticiasService: NoticiasService,
    private bannersService: BannersService,
    public geolocation: Geolocation,
    public waypointsService: WaypointsService,
    private mapsService: MapsService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private activatedRoute: ActivatedRoute) {

    this.id_dia = activatedRoute.snapshot.params["dia"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.backurl= this.router.getCurrentNavigation().extras.state.back;
      }
    });
    
    this.geolocation.getCurrentPosition().then((position) =>  {
        console.log(position);
    });

  }

  async ngOnInit() {

    this.loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      spinner: 'circles',
    });

    this.loading.present();

  }

  ngOnDestroy() {
      clearTimeout(this.timeout);
  }

  initMap(){

    if (typeof google === 'object' && typeof google.maps === 'object'){
      this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
      this.infowindow = new google.maps.InfoWindow({
          content: ''
      });
      
      this.getBanners();

      this.getPosicionesCofradia();
      this.getCofradias();

      this.rand = 'ios-' + Date.now() + '-Multimapa' + this.id_dia;
      this.timeoutuser = setTimeout(() => this.getCurrentCoordinates(), 5000);

    }
  }

  loadApiMap() {

    this.mapsService.getApiKey().subscribe((data) => {

      var request: any = data;
      this.apiKey = request.apikey;
      const script = this._renderer2.createElement('script');
      script.id = 'googleMaps';
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&callback=Function.prototype';

      this._renderer2.appendChild(this._document.body, script);
      setTimeout(() => this.init(), 1000);
    });

  }

  init(){

    this.loading.dismiss();
    this.showmap = true;

    this.getBannerSuperior();

    this.mapOptions = {
      center: this.location,
      zoom: 14,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [{
        featureType: "poi",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }]
    };

    setTimeout(() => {
      this.initMap()
    }, 10);

  }

  ngAfterViewInit() {

    if (document.getElementById('googleMaps')) {
      document.getElementById('googleMaps').remove();
    }

    setTimeout(() => {
      this.loadApiMap();
    },10);



  }

  getBannerSuperior(){
    this.bannersService.getBanner('banner_mapa').subscribe(
      data => {
        var response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }


  getBanners(){
    this.banners = [];
    this.bannersService.getBanner('geoposicion').subscribe(
      data => {
        var response: any = data;
        this.banners = response.banners;
      }
    )
  }

  back(){
    clearTimeout(this.timeout);
    this.router.navigate(['dia',this.id_dia]);
  }

  getWaypoints(id_cofradia){

    //miramos si hay guardados y si no los pedimos
    this.getStoreWaypoints(id_cofradia);

  }

  async getStoreWaypoints(id_cofradia: any) {
    this.waypoints = await get("waypoints_"+id_cofradia);

    if (this.waypoints === null || this.waypoints === undefined){
      this.waypointsService.getWaypoints(id_cofradia).subscribe(
        data => {
          var response: any = data;
          this.waypoints = response.waypoints;
          set("waypoints_"+id_cofradia, this.waypoints);
          this.pintarWaypoints();
        }
      )
    }else{
      this.pintarWaypoints();
    }

  }

  pintarWaypoints(){

    var recorrido = [];
    var mostrar= 1;
    for (let waypoint of this.waypoints){
      if(mostrar == 1){
        var obj: any = { lat: waypoint.latitud,lng: waypoint.longitud};
        recorrido.push(obj);
      }
      if (waypoint.sentido == 1){
        mostrar = 0;
      }
    }

    var recorridovuelta = [];

    for (let waypoint of this.waypoints){
      if (waypoint.sentido == 1){
        var obj: any = { lat: waypoint.latitud,lng: waypoint.longitud};
        recorridovuelta.push(obj);
      }
    }

    console.log(recorrido);

    if (this.path){
      this.path.setMap(null);
    }
    this.path = new google.maps.Polyline({
      path: recorrido,
      geodesic: true,
      strokeColor: '#dda01c',
      strokeOpacity: 1.0,
      strokeWeight: 5
    });
    
    this.path.setMap(this.map);

    if (this.pathvuelta){
      this.pathvuelta.setMap(null);
    }
    this.pathvuelta = new google.maps.Polyline({
      path: recorridovuelta,
      geodesic: true,
      strokeColor: '#2364a1',
      strokeOpacity: 1.0,
      strokeWeight: 5
    });

    this.pathvuelta.setMap(this.map);

    console.log(this.waypoints);
  }

  getPosicionesCofradia(){
    this.waypointsService.getPosicionesCofradiasDia(this.id_dia).subscribe(
      data => {
        console.log(data);

        if (this.waypointsArray) {
          for (let marker of this.waypointsArray) {
            marker.setMap(null);
          }
          this.waypointsArray = [];
        }

        var puntos: any = data;
        for (let punto of puntos.g){
          
          var point = new google.maps.LatLng(punto.l,punto.n);

          var cofradia: any = {};
          for (let cof of this.cofradias){
            if (cof.id_cofradia == punto.c){
              cofradia = cof;
            }
          }

          var image = {
              url: "/assets/img/escudos/" + cofradia.escudo,
              scaledSize : new google.maps.Size(50, 50)
          };

          var marker = new google.maps.Marker({
            position: point,
            html: cofradia.nombre_corto,
            id_cofradia: punto.c,
            map: this.map,
            icon: image
          });

          var that = this;
          google.maps.event.addListener(marker, 'click', function() {
            that.infowindow.setContent(this.html);
            that.infowindow.open(that.map,this);
            that.getVersionWaypointsCofradia(this.id_cofradia);
          });

          this.waypointsArray.push(marker);

          /*if (this.panmap){
            this.panmap = false;
            this.map.panTo(point);
          }*/

        }
        
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=> this.getPosicionesCofradia(), 5000);
        
      }
    )
    
  }

  mostrarBanners(){

    if (this.markersBanners) {
      for (let marker of this.markersBanners) {
        marker.setMap(null);
      }
      this.markersBanners = [];
    }

    for (let banner of this.banners){
      
        var point = new google.maps.LatLng(banner.latitud,banner.longitud);

        var image = {
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            scaledSize : new google.maps.Size(50, 50)
        };
        
        var marker = new google.maps.Marker({
          position: point,
          html: '<a href="'+banner.url+'">'+banner.nombre+'</a>',
          map: this.map,
          icon: image
        });

        var that = this;
        google.maps.event.addListener(marker, 'click', function() {
          that.infowindow.setContent(this.html);
          that.infowindow.open(that.map,this);
        });

        this.markersBanners.push(marker);
      
    }

  }

  getVersionWaypointsCofradia(id_cofradia){
    // tenemos que ver la version de los datos de las cofradias para recargarlas o no
    this.waypointsService.getVersionWaypointsCofradia(id_cofradia).subscribe(
      data => {
        var response: any = data;
        this.comprobarVersion(response, id_cofradia);
      }
    ) 

  }

  async comprobarVersion(response, id_cofradia) {
    this.version_waypoints = await get("version_waypoints_"+id_cofradia);

    if (this.version_waypoints === null || this.version_waypoints === undefined  || this.version_waypoints !== response.version){
      this.version_waypoints = response.version;
      set("version_waypoints_"+id_cofradia, this.version_waypoints);
      this.waypointsService.getWaypoints(id_cofradia).subscribe(
        data => {
          var response: any = data;
          this.waypoints = response.waypoints;
          set("waypoints_"+id_cofradia, this.waypoints);
          this.pintarWaypoints();
        }
      )
    }else{
      this.getWaypoints(id_cofradia);
    }

  }

  async getCofradias() {
    this.cofradias = await get("cofradias");
    console.log(this.cofradias);
  }

  // use geolocation to get user's device coordinates
  getCurrentCoordinates() {

    this.geolocation.getCurrentPosition().then((resp) => {
      const latitude = resp.coords.latitude;
      const longitude = resp.coords.longitude;
      this.waypointsService.setUserLocation(latitude, longitude, this.rand).subscribe(
          data => {

            if (this.miposicionArray) {
              for (const m of this.miposicionArray) {
                m.setMap(null);
              }
              this.miposicionArray = [];
            }

            var point = new google.maps.LatLng(latitude,longitude);

            var image = {
              url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
              scaledSize : new google.maps.Size(50, 50)
            };

            var marker = new google.maps.Marker({
              position: point,
              html: '<b>Tu posición</b><br><br>',
              map: this.map,
              icon: image
            });

            var that = this;
            google.maps.event.addListener(marker, 'click', function() {
              that.infowindow.setContent(this.html);
              that.infowindow.open(that.map,this);
            });

            this.miposicionArray.push(marker);
          }
      )
    }).catch((error) => {
      console.log('Error getting location', error);
    });

    this.timeoutuser = setTimeout(() => this.getCurrentCoordinates(), 5000);

  }

}
