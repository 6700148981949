import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../_services/noticias.service';
import { BannersService } from '../_services/banners.service';

@Component({
  selector: 'noticias',
  templateUrl: 'noticias.template.html',
  styleUrls: ['noticias.template.css'],
})
export class NoticiasComponent implements OnInit, OnDestroy, AfterViewInit {

  noticias: any = [];
  banner: any;

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private bannersService: BannersService,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {

    this.getNoticias();

  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.getBanner();
  }

  getBanner(){
    this.bannersService.getBanner('movil_noticias').subscribe(
      data => {
        console.log(data);
        const response: any = data;
        if (response.banners[0]){
          this.banner = response.banners[0];
        }
      }
    )
  }

  getNoticias(){
    this.noticiasService.getNoticias().subscribe(
      data => {
        console.log(data);
        const response: any = data;
        this.noticias = response.noticias;
      }
    )
  }

  recargarNoticias(event){

    this.noticiasService.getNoticias().subscribe(
      data => {
        console.log(data);
        const response: any = data;
        this.noticias = response.noticias;
        event.target.complete();
      }
    )
    
  }

  getNoticia(noticia: any) {
      const navigationExtras: NavigationExtras = { state: {noticia: noticia, back: 'inicio'} };
      this.router.navigate(['noticia',noticia.id], navigationExtras);
    
  }

}
