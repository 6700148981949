import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, Inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticiasService } from '../_services/noticias.service';
import {MapsService} from '../_services/maps.service';
import {DOCUMENT} from '@angular/common';

declare var google: any;

@Component({
  selector: 'evento',
  templateUrl: 'evento.template.html',
  styleUrls: ['evento.template.css'],
})
export class EventoComponent implements OnInit, OnDestroy {

  evento: any = {};
  backurl: any;
  id_noticia: any;

  @ViewChild('Map', {static: false}) mapElement: ElementRef;

  map: any;
  infowindow: any;
  mapOptions: any;
  location = {lat: 37.766259, lng: -3.788640};
  markerOptions: any = {position: null, map: null, title: null};
  marker: any;
  apiKey = '';

  constructor(
    private router: Router,
    private noticiasService: NoticiasService,
    private mapsService: MapsService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private activatedRoute: ActivatedRoute) {

    this.id_noticia = activatedRoute.snapshot.params["id"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.evento = this.router.getCurrentNavigation().extras.state.evento;
        this.backurl = this.router.getCurrentNavigation().extras.state.back;
      }
    });
    
  }

  ngOnInit() {

    console.log("Evento");

  }

  ngOnDestroy() {
      
  }

  loadApiMap(){
    this.mapsService.getApiKey().subscribe((data) => {

      var request: any = data;
      this.apiKey = request.apikey;
      const script = this._renderer2.createElement('script');
      script.id = 'googleMaps';
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&callback=Function.prototype';

      this._renderer2.appendChild(this._document.body, script);
      setTimeout(() => this.init(), 1000);
    });
  }

  init(){
    this.location.lat = this.evento.latitud;
    this.location.lng = this.evento.longitud;

    this.mapOptions = {
      center: this.location,
      zoom: 18,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [{
        featureType: "poi",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }]
    };

    setTimeout(() => {
      this.initMap()
    }, 10);

  }

  ngAfterViewInit() {

    if (document.getElementById('googleMaps')) {
      document.getElementById('googleMaps').remove();
    }

    setTimeout(() => {
      this.loadApiMap();
    },10);

  }

  initMap(){

    if (typeof google === 'object' && typeof google.maps === 'object'){
      this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
      this.markerOptions.position = this.location;
      this.markerOptions.map = this.map;
      this.markerOptions.title = this.evento.lugar;
      this.marker = new google.maps.Marker(this.markerOptions);
      this.infowindow = new google.maps.InfoWindow({
          content: ''
      });
    }
  }

  back(){
    this.router.navigate(['/' + this.backurl]);
  }


}
