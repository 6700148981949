import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()

export class CofradiasService {

    constructor(private http: HttpClient) { }

    getCofradias(){
        console.log("pido cofradias");
        var url = 'http://dev.pasionenjaen.com/cofradias-io/';
        return this.http.get(url);

    }

    getCofradiasSuspendidas(){
        console.log("pido cofradias");
        var url = 'http://dev.pasionenjaen.com/cofradias/suspendidas/';
        return this.http.get(url);

    }

    getVersionDatos(){
        var url = 'http://dev.pasionenjaen.com/versiones/cofradias/';
        return this.http.get(url);
    }

    getDias(){
        var url = 'http://dev.pasionenjaen.com/dias/';
        return this.http.get(url);
    }
    
    getBandas(id_cofradia: any){
        console.log("pido cofradias");
        var url = 'http://dev.pasionenjaen.com/cofradia/'+id_cofradia+'/bandas/';
        return this.http.get(url);
    }

    getImagineria(id_cofradia: any){
        console.log("pido cofradias");
        var url = 'http://dev.pasionenjaen.com/cofradia/'+id_cofradia+'/imagineria/';
        return this.http.get(url);
    }

    getPois(id_cofradia: any){
        console.log("pido cofradias");
        var url = 'http://dev.pasionenjaen.com/pois/'+id_cofradia;
        return this.http.get(url);

    }

    getTimeline(){
        console.log("pido timeline");
        var url = 'http://dev.pasionenjaen.com/timeline-cache/';
        return this.http.get(url);
    }

}