import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()

export class RadioService {

    constructor(private http: HttpClient) { }

    getCategorias(){
        var url = 'http://dev.pasionenjaen.com/radio/categorias/';
        return this.http.get(url);

    }

    getProgramas(categoria: any){
        var url = 'http://dev.pasionenjaen.com/radio/categorias/'+categoria+'/';
        return this.http.get(url);
    }

}