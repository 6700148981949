import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()

export class BannersService {

    constructor(private http: HttpClient) { }

    getBanner(tipo: any){

        var url = 'http://dev.pasionenjaen.com/banners/'+tipo+'/';
        return this.http.get(url);

    }

}