import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

//import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InicioComponent } from './inicio/inicio.component';
import { HttpClientModule } from '@angular/common/http';
import { NoticiasService } from './_services/noticias.service';
import { NoticiasComponent } from './noticias/noticias.component';
import { NoticiaComponent } from './noticia/noticia.component';
import { BannersService } from './_services/banners.service';
import { ListaCofradiasComponent } from './listacofradias/listacofradias.component';
import { DetalleCofradiaComponent } from './detallecofradia/detallecofradia.component';
import { CofradiasService } from './_services/cofradias.service';
import { MapaComponent } from './detallecofradia/mapa/mapa.component';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import { WaypointsService } from './_services/waypoints.service';
import { ListaProgramasComponent } from './listaprogramas/listaprogramas.component';
import { AgendaComponent } from './agenda/agenda.component';
import { RadioService } from './_services/radio.service';
import { PlayerComponent } from './listaprogramas/player/player.component';
import { Media } from '@ionic-native/media/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { FormsModule } from '@angular/forms';
import { GlobalService } from './_services/global.service';
import { EventosService } from './_services/eventos.service';
import { EventoComponent } from './evento/evento.component';
import { DiaSemanaSantaComponent } from './diasemanasanta/diasemanasanta.component';
import { InformacionComponent } from './informacion/informacion.component';
import { MultimapaComponent } from './diasemanasanta/multimapa/multimapa.component';
import { BandasService } from './_services/bandas.service';
import { ListaBandasComponent } from './listabandas/listabandas.component';
import { DetalleBandaComponent } from './detallebanda/detallebanda.component';
import { ErrorService } from './_services/error.service';
import { ErrorReportingComponent } from './errorreporting/errorreporting.component';
import { TimelineComponent } from './timeline/timeline.component';
import { DetalleImagenComponent } from './detalleimagen/detalleimagen.component';
import { DiaSemanaSantaComponentComponent } from './diasemanasanta/diasemanasantacomponent.component';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    NoticiasComponent,
    NoticiaComponent,
    ListaCofradiasComponent,
    ListaBandasComponent,
    DetalleCofradiaComponent,
    DetalleBandaComponent,
    DetalleImagenComponent,
    MapaComponent,
    ListaProgramasComponent,
    PlayerComponent,
    AgendaComponent,
    EventoComponent,
    DiaSemanaSantaComponent,
    DiaSemanaSantaComponentComponent,
    InformacionComponent,
    MultimapaComponent,
    ErrorReportingComponent,
    TimelineComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot({mode: 'md'}),
    AppRoutingModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NoticiasService,
    CofradiasService,
    BandasService,
    BannersService,
    Geolocation,
    EventosService,
    WaypointsService,
    RadioService,
    GlobalService,
    ErrorService,
    SocialSharing,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    //NativePageTransitions,
    Media,
    BackgroundMode
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
